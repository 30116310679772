import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import stanfordSonomaLogo from '../../../static/images/Stanford Sonoma Circle Logo.png';
import { HeaderData } from '../../data/header';
import { Wrapper, NavigationStyle, Logo, PhoneDiv, QuoteDiv, Inner } from '../../styled-components/header';
import { Button } from '../ui-elements/button';
import {
  closeMenuHandler,
  handleMouseOver,
  handleMenuKeyDown,
  handleMouseOut,
  handleToggleSubmenu,
  menuOpenHandler,
} from './functions';
// import useWindowSize from '../../hooks/useWindowSize';
import phoneValidator from 'phone';

export default () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        strapiSsContactPage {
          id
          info_form_phone
        }
      }
    `}
    render={(data) => {
      const { strapiSsContactPage } = data;
      // const { width } = useWindowSize();

      return (
        <Wrapper>
          <Inner>
            <NavigationStyle>
              <button type="button" className="header__nav__close-btn" onClick={closeMenuHandler}>
                Close
              </button>
              <nav
                id="mainNav"
                aria-label="Main Navigation"
                onKeyDown={(e) => handleMenuKeyDown(e)}
              >
                <ul className="header__nav__menu" role="menubar">
                  {HeaderData.map((mainData) => {
                    return mainData.image === undefined ? (
                      mainData.label === undefined ? (
                        <li
                          key={mainData.id}
                          onMouseOver={(e) => handleMouseOver(e, mainData.id)}
                          onMouseOut={(e) => handleMouseOut(e, mainData.id)}
                          onClick={() => closeMenuHandler(mainData.id)}
                        >
                          <Link activeClassName="activeLink" to={mainData.link}>
                            {mainData.title}
                          </Link>
                        </li>
                      ) : (
                        <li
                          className="header__nav__menu__item"
                          key={mainData.id}
                          onMouseOver={(e) => handleMouseOver(e, mainData.id)}
                          onMouseOut={(e) => handleMouseOut(e, mainData.id)}
                        >
                          <Button
                            type="button"
                            role="menuitem"
                            aria-haspopup
                            aria-expanded={false}
                            aria-controls={`header__nav__submenu-${mainData.id}`}
                            blank
                            data-id={mainData.id}
                            data-level="0"
                            onClick={() => handleToggleSubmenu(mainData.id)}
                          >
                            {mainData.title}
                          </Button>
                          <ul
                            className="header__nav__submenu"
                            hidden
                            id={`header__nav__submenu-${mainData.id}`}
                          >
                            {mainData.label.map((subData) => {
                              return (
                                <>
                                  <li className="inline-flex-wrapper">
                                    {subData.title && subData.link ? (
                                      <li
                                        key={subData.id}
                                        onClick={() => closeMenuHandler(subData.id)}
                                      >
                                        <Link
                                          className="labelTitle"
                                          to={subData.link}
                                          key={subData.id}
                                          data-id={subData.id}
                                          data-level="1"
                                          tabIndex="-1"
                                          onClick={() => closeMenuHandler(subData.id)}
                                          role="menuitem"
                                        >
                                          {subData.title}
                                        </Link>
                                      </li>
                                    ) : (
                                      <li className="labelTitle">{subData.title}</li>
                                    )}
                                    {subData.subTitles.map((subTitleData) => {
                                      return (
                                        <li
                                          key={subTitleData.id}
                                          onClick={() => closeMenuHandler(subData.id)}
                                        >
                                          {(subTitleData.link &&
                                          !subTitleData.external) ? (
                                            <Link
                                              to={subTitleData.link}
                                              key={subTitleData.id}
                                              data-id={subData.id}
                                              data-level="1"
                                              tabIndex="-1"
                                              onClick={() => closeMenuHandler(subTitleData.id)}
                                              role="menuitem"
                                            >
                                              {subTitleData.title}
                                            </Link>
                                          ) : (subTitleData.link &&
                                              subTitleData.external) ? (
                                            <a
                                              href={subTitleData.link}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {subTitleData.title}
                                            </a>
                                          ) : (
                                            <p
                                              key={subTitleData.id}
                                              data-id={subData.id}
                                              data-level="1"
                                              tabIndex="-1"
                                              // onClick={() => closeMenuHandler(subTitleData.id)}
                                              role="menuitem"
                                            >
                                              {subTitleData.title}
                                            </p>
                                          )}
                                        </li>
                                      );
                                    })}
                                  </li>
                                </>
                              );
                            })}
                          </ul>
                        </li>
                      )
                    ) : (
                      <li
                        key={mainData.id}
                        onMouseOver={(e) => handleMouseOver(e, mainData.id)}
                        onMouseOut={(e) => handleMouseOut(e, mainData.id)}
                        onClick={() => closeMenuHandler(mainData.id)}
                      >
                        {/*{width > theme.breakpoints.tabletLandscapeNum ? (*/}
                        <Link to={mainData.link}>
                          <Logo src={stanfordSonomaLogo} alt="Stanford Sonoma logo image" />
                        </Link>
                      </li>
                    );
                  })}
                </ul>
                <QuoteDiv>
                  <a href={'/our-company/contact'}>Get A Quote</a>
                </QuoteDiv>
                <PhoneDiv>
                  <a href={`tel:${phoneValidator(strapiSsContactPage.info_form_phone)[0]}`}>
                    <i className="fas fa-phone" />
                    <span>{strapiSsContactPage.info_form_phone}</span>
                  </a>
                </PhoneDiv>
              </nav>
            </NavigationStyle>
            <div className="header__actions">
              <Link to={'/'}>
                <Logo
                  className="mobile-logo"
                  src={stanfordSonomaLogo}
                  alt="Stanford Sonoma logo image"
                />
              </Link>
              <button
                type="button"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target="#bs-example-navbar-collapse-1"
                onClick={menuOpenHandler}
              >
                <span className="icon-bar" aria-hidden="true" />
                <span className="icon-bar" aria-hidden="true" />
                <span className="icon-bar" aria-hidden="true" />
                <span className="visually-hidden">Toggle Mobile Menu</span>
              </button>
            </div>
          </Inner>
        </Wrapper>
      );
    }}
  />
);
