import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import phoneValidator from 'phone';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        linkedIn: file(relativePath: { eq: "images/In-White-54px-R.png" }) {
          childImageSharp {
            fixed(width: 45, height: 35) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        facebook: file(relativePath: { eq: "images/facebook.png" }) {
          childImageSharp {
            fixed(width: 65, height: 65) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        thomas: file(relativePath: { eq: "images/shield-tier-c-lg.png" }) {
          childImageSharp {
            fixed(width: 130, height: 130) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        strapiSsContactPage {
          id
          info_form_phone
          info_form_email
          info_form_address
        }
      }
    `}
    render={(data) => {
      const { strapiSsContactPage } = data;

      return (
        <footer>
          <div className={'Footer__mainWrapper'}>
            <div className={'Footer__spanRow'}>
              <h2>
                Connect with us:
              </h2>
                <div className={'Footer__mainWrapper--socialDivParent'}>
                  <div className={'Footer__mainWrapper--socialDiv'}>
                    <p>LinkedIn</p>
                    <a
                      href="https://www.linkedin.com/company/stanford-sonoma"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Img
                        className={'Footer__mainWrapper--linkedInImg'}
                        fixed={data.linkedIn.childImageSharp.fixed}
                        alt="Linkeding logo image"
                      />
                    </a>
                  </div>
                  <div className={'Footer__mainWrapper--socialDiv'}>
                   <p>Facebook</p>
                    <a
                      href="https://www.facebook.com/pg/stanfordsonoma/posts/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Img
                        className={'Footer__mainWrapper--linkedInImg'}
                        fixed={data.facebook.childImageSharp.fixed}
                        alt="Facebook logo image"
                      />
                    </a>
                  </div>
                </div>
            </div>

            <div className={'Footer__iconsDiv'}>
              <h2 className={'Footer__helperH2'}>Stanford Sonoma Corp.</h2>
              <div className={'Footer__fontawesomeIconsStyling'}>
                <i className="fas fa-map-marker-alt" />
                <a
                  href="https://www.google.com/maps/place/12750+Perimeter+Dr+%23154,+Dallas,+TX+75228,+Sjedinjene+Dr%C5%BEave/@32.8601574,-96.6621586,17z/data=!3m1!4b1!4m5!3m4!1s0x864ea72bc9e7533f:0x762a26c941cbfaf1!8m2!3d32.8601574!4d-96.6599699"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {strapiSsContactPage.info_form_address}
                </a>
              </div>
              <div className={'Footer__fontawesomeIconsStyling'}>
                <i className="fas fa-envelope-open-text" />
                <a href={`mailto:${strapiSsContactPage.info_form_email}`}>
                  {strapiSsContactPage.info_form_email}
                </a>
              </div>
              <div className={'Footer__fontawesomeIconsStyling'}>
                <i className="fas fa-phone" />
                <a href={`tel:${phoneValidator(strapiSsContactPage.info_form_phone)[0]}`}>
                  {strapiSsContactPage.info_form_phone}
                </a>
              </div>
              {/*<div className={'Footer__external-link-wrapper'}>*/}
              {/*  <i className="fas fa-link" />*/}
              {/*  <a href='https://stanfordequipment.com/' target='_blank'>Stanford Equipment</a>*/}
              {/*</div>*/}
            </div>
            <div className={'Footer__thomas__badge'}>
              <div className={'Footer__thomas__badge-terms-wrapper'}>
                <a href='/privacy-policy-and-cookies'>Terms & Conditions</a>
              </div>
              <Img
                className={'tn-badge__img'}
                fixed={data.thomas.childImageSharp.fixed}
                alt="Thomas Supplier"
              />
            </div>
          </div>
        </footer>
      );
    }}
  />
);
