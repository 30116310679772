import theme from "../../theme";

export const keyCodes = {
  TAB: 9,
  RETURN: 13,
  ESC: 27,
  SPACE: 32,
  PAGEUP: 33,
  PAGEDOWN: 34,
  END: 35,
  HOME: 36,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  ENTER: 13,
  ESCAPE: 27,
}

export const menuOpenHandler = () => {
  const isMenuOpenClassName = theme.classNames.isMenuOpen;

  document.body.classList.add(isMenuOpenClassName);
};

export const openSubmenu = (id) => {
  const button = document.querySelector(
    `[aria-controls="header__nav__submenu-${id}"]`
  )
  const submenu = document.getElementById(`header__nav__submenu-${id}`)
  if (submenu) {
    button.setAttribute("aria-expanded", "true")
    submenu.setAttribute("hidden", false)
    submenu.style.display = "inline-flex"
  }
}

export const closeSubmenu = (id) => {
  const button = document.querySelector(
    `[aria-controls="header__nav__submenu-${id}"]`
  )
  const submenu = document.getElementById(`header__nav__submenu-${id}`)
  if (submenu) {
    button.setAttribute("aria-expanded", "false")
    submenu.setAttribute("hidden", true)
    submenu.style.display = "none"
  }
}

export const getIndex = (element, collection) => {
  let index = -1
  collection.forEach((el, i) => {
    if (element === el) {
      index = i
    }
  })
  return index
}

export const focusNext = (element, collection) => {
  const index = getIndex(element, collection)
  if (index !== -1) {
    const { length } = collection
    index === length - 1 ? collection[0].focus() : collection[index + 1].focus()
  }
}

export const focusPrev = (element, collection) => {
  const index = getIndex(element, collection)
  if (index !== -1) {
    const { length } = collection
    // eslint-disable-next-line no-unused-expressions
    index === 0 ? collection[length - 1].focus() : collection[index - 1].focus()
  }
}

export const handleMenuKeyDown = (e) => {
  const { target, keyCode } = e
  const level = target.getAttribute("data-level")

  if (level) {
    const mainNav = document.getElementById("mainNav")
    const menuElements = mainNav.querySelectorAll('[data-level="0"]')
    let collection = menuElements

    let button = null
    let submenuElements = null

    if (level == 0) {
      if (target.nextElementSibling !== null) {
        button = target
        submenuElements = target.nextElementSibling.querySelectorAll(
          '[data-level="1"]'
        )
      }
    } else {
      button = target.parentNode.parentNode.previousElementSibling
      submenuElements = target.parentNode.parentNode.querySelectorAll(
        '[data-level="1"]'
      )
      collection = submenuElements
    }

    switch (keyCode) {
      case keyCodes.SPACE:
      case keyCodes.ENTER:
        if (level == 0) {
          if (submenuElements !== null && button !== null) {
            e.preventDefault()
            openSubmenu(button.getAttribute("data-id"))
            submenuElements[0].focus()
          }
        } else if (submenuElements !== null && button !== null) {
          closeSubmenu(button.getAttribute("data-id"))
        }
        break
      case keyCodes.ESCAPE:
        e.preventDefault()
        if (submenuElements !== null && button !== null) {
          closeSubmenu(button.getAttribute("data-id"))
          button.focus()
        }
        break
      case keyCodes.DOWN:
        e.preventDefault()
        if (level == 0) {
          if (submenuElements !== null && button !== null) {
            openSubmenu(button.getAttribute("data-id"))
            submenuElements[0].focus()
          }
        } else {
          focusNext(target, collection)
        }
        break
      case keyCodes.UP:
        e.preventDefault()
        level == 1 && focusPrev(target, collection)
        break
      case keyCodes.LEFT:
        if (level == 0) {
          focusPrev(target, collection)
        } else {
          closeSubmenu(button.getAttribute("data-id"))
          focusPrev(button, menuElements)
        }
        break
      case keyCodes.RIGHT:
        if (level == 0) {
          focusNext(target, collection)
        } else {
          closeSubmenu(button.getAttribute("data-id"))
          focusNext(button, menuElements)
        }
        break

      default:
        break
    }
  }
}

export const handleMouseOver = (e, id) => {
  const w = window.innerWidth
  if (w > theme.breakpoints.tabletLandscapeNum) openSubmenu(id)
}

export const handleMouseOut = (e, id) => {
  const w = window.innerWidth
  if (w > theme.breakpoints.tabletLandscapeNum) closeSubmenu(id)
}

export const handleToggleSubmenu = (id) => {
  const w = window.innerWidth
  if (w < theme.breakpoints.tabletLandscapeNum) {
    const button = document.querySelector(
      `[aria-controls="header__nav__submenu-${id}"]`
    )
    const isExpanded = button.getAttribute("aria-expanded")
    // eslint-disable-next-line no-unused-expressions
    isExpanded === "true" ? closeSubmenu(id) : openSubmenu(id)
  }
}

export const closeMenuHandler = () => {
  document.activeElement.blur()
  const isMenuOpenClassName = theme.classNames.isMenuOpen
  document.body.classList.remove(isMenuOpenClassName)
}
