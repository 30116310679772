import React from "react"
import styled, { css } from "styled-components"

import theme from "../../theme"

const style = () => css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  border-radius: ${theme.buttonHeight};
  background: ${theme.colors.yellow};
  color: ${theme.colors.black} !important;
  padding: 0 ${theme.spacingXL};
  height: ${theme.buttonHeight};
  cursor: pointer;
  vertical-align: middle;
  text-decoration: none !important;
  font-size: ${theme.fontSizeBody};
  font-weight: ${theme.fontWeightBold};
  font-family: ${theme.fontFamilyHeadings};
  font-style: normal;
  transition: ${theme.transitionBase};
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 5px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  span {
    color: ${theme.colors.black} !important;
  }

  @media only screen and (max-width: ${theme.breakpoints.tabletPortrait}) {
    padding: 0 ${theme.spacingBase};

    span {
      text-align: center;
    }
  }

  span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    height: 100%;

    img {
      height: 70%;
      width: auto;

      &:first-child {
        margin-right: ${theme.spacingS};
      }
      &:last-child {
        margin-left: ${theme.spacingS};
      }
    }
  }

  ${(props) =>
    !props.disabled &&
    !props.important &&
    `
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 150%;
      left: 100%;
      top: -25%;
      background: rgb(255,218,0);
      background: linear-gradient(90deg, rgba(255,218,0,1) 0%, rgba(255,218,0,1) 37%, rgba(255,145,0,1) 100%);
      transition: ${theme.transitionBase};
      z-index: 0;
    }

    &:active,
    &:focus,
    &:hover {
      border: 2px solid transparent;

      &:before {
          left: 0;
      }
    }
  `}

  ${(props) =>
    props.important &&
    `
    background: ${theme.colors.red};
    border: 2px solid ${theme.colors.red};
    color: #fff !important;

    span {
      color: #fff !important;
    }

    &:before {
        display: none;
    }

    &:hover {
      border: 2px solid ${theme.colors.darkRed};
      background: ${theme.colors.darkRed};
    }

    &:active,
    &:focus,
    &:hover {
        &:before {
            display: none;
        }
    }
  `}

  ${(props) =>
    props.inverted &&
    !props.important &&
    `
    background: #fff;
    border: 2px solid ${theme.colors.yellow};

    &:before {
        left: 0;
        opacity: 0;
    }

    &:active,
    &:focus,
    &:hover {
        &:before {
            opacity: 1;
        }
    }
  `}

  ${(props) =>
    props.inverted &&
    props.important &&
    `
    color: ${theme.colors.black} !important;
    background: #fff;
    border: 2px solid ${theme.colors.red};

    span {
      color: ${theme.colors.black} !important;
    }

    &:before {
        left: 0;
        opacity: 0;
    }

    &:hover {
      color: #fff !important;
      border: 2px solid ${theme.colors.red};
      background: ${theme.colors.red};

      span {
        color: #fff !important;
      }
    }

    &:active,
    &:focus,
    &:hover {
        &:before {
            display: none;
        }
    }
  `}

  &:focus,
  &:focus:active {
    border: 2px solid #ff9100;
    box-shadow: ${theme.boxShadowBase};
  }

  &:focus:not(:active) {
    box-shadow: ${theme.boxShadowMedium};
  }

  ${(props) =>
    props.disabled &&
    `
    pointer-events: none;
    cursor: default;
    opacity: .5;
  `}

  ${(props) =>
    props.block &&
    `
    width: 100%;
    margin: 5px 0;
  `}

  ${(props) => props.customCSS && props.customCSS}
`

const ButtonWrapper = styled.button`
  ${(props) => !props.blank && style}
  ${(props) =>
    props.blank &&
    `
    z-index: 101;
    background: none;
    border: none;
    font-size: inherit;
    line-height: inherit;
    padding: 0;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
  `}
`

export const Button = ({
  children,
  className,
  blank,
  block,
  disabled,
  inverted,
  important,
  onBlur,
  onClick,
  onFocus,
  onKeyPress,
  type,
  tabIndex,
  ...props
}) => (
  <ButtonWrapper
    blank={blank}
    type={type}
    block={block}
    className={className}
    disabled={disabled}
    important={important}
    inverted={inverted}
    onBlur={onBlur}
    onClick={onClick}
    onFocus={onFocus}
    onKeyPress={onKeyPress}
    tabIndex={tabIndex || "0"}
    {...props}
  >
    <span>{children}</span>
  </ButtonWrapper>
)

const LinkWrapper = styled.a`
  ${style}
`;

export const LinkButton = React.forwardRef(
  (
    {
      className,
      onClick,
      href,
      target,
      children,
      disabled,
      inverted,
      block,
      important,
      tabIndex,
      ...props
    },
    ref,
  ) => {
    return (
      <LinkWrapper
        href={href}
        target={target}
        className={className}
        onClick={onClick}
        ref={ref}
        disabled={disabled}
        inverted={inverted}
        block={block}
        important={important}
        tabIndex={tabIndex || '0'}
        {...props}
      >
        <span>{children}</span>
      </LinkWrapper>
    );
  },
);
