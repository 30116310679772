import styled from 'styled-components';
import cdown from '../../../static/images/chevron-down.svg';
import closeicon from '../../../static/images/close_btn_mobile.svg';
import theme from '../../theme';

const headerDesktopHeight = '80px';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 45px 0;
  background-color: white;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.8);
  z-index: 5;
  text-align: center;
  ul {
    display: inline-flex;
    list-style-type: none;
  }
  li {
    @media (min-width: 0) {
      margin: 0 20px;
    }
    @media (min-width: 1500px) {
      margin: 0 50px;
    }
    align-self: center;

    a, p {
      @media (min-width: 0) {
        margin: 0;
        font-size: 16px;
      }
      @media (min-width: 1500px) {
        font-size: 20px;
      }
      font-size: 20px;
      font-family: Lato, sans-serif;
      font-weight: 400;
      text-decoration: none;
      color: rgba(0, 0, 0, 0.8);
      border-bottom: 2px solid #fff;

      &:hover {
        color: var(--base-color);
        border-bottom: 2px solid var(--base-color);
      }
    }
    p {
      &:hover {
        color: #333333 !important;
        border-bottom: 2px solid #fff !important;
      }
    }
  }
  .activeLink {
    color: var(--base-color);
    border-bottom: 2px solid var(--base-color);
  }
  @media only screen and (min-width: ${theme.breakpoints.tabletLandscape}) {
    padding: 20px 0 10px 0;
  }
`;

export const Inner = styled.header`
  position: relative;
  z-index: 100;
  .visually-hidden {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    margin: 0;
  }

  .mobile-logo {
    margin: 20px 30px;
    @media only screen and (min-width: ${theme.breakpoints.tabletLandscape}) {
      display: none;
    }
  }

  .header__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > button {
      border: 0.5px solid #c3c4cc;
      background-color: #fff;
      border-radius: 8px;
      padding: 0.75rem;
      margin-right: 30px;

      .icon-bar {
        display: block;
        width: 22px;
        height: 2px;
        border-radius: 1px;
        background-color: #000;
        margin: 3px;
      }
    }
  }

  @media only screen and (max-width: 959px) {
    position: fixed;
    background: #fff;
    top: 0;
    left: 0;
    width: 100%;
    transition: ${theme.transitionBase};

    .${theme.classNames.isMenuOpen} & {
      left: -30%;
    }
  }

  @media only screen and (min-width: ${theme.breakpoints.tabletLandscape}) {
    .navbar-toggle {
      display: none;
    }
  }
`;

export const NavigationStyle = styled.div`
  nav {
    margin: 0 auto;
  }
  .header__nav {
    ul {
      margin: 0;
      padding: 0 !important;
    }

    li {
      margin: 0;
      padding: 0;
      list-style: none;

      a,
      a:hover {
        text-decoration: none;
      }
    }

    &__menu__item {
      > a {
        &:focus {
          border: none;
        }
        &:focus:not(:active) {
          text-shadow: ${theme.boxShadowFocus};
        }
      }

      > button {
        position: relative;
        border-bottom: 2px solid #fff;

        span {
          color: rgba(0, 0, 0, 0.8);
          @media (min-width: 0) {
            font-size: 20px;
          }
          @media (min-width: 960px) {
            font-size: 16px;
          }
          @media (min-width: 1500px) {
            font-size: 20px;
          }
          font-family: Lato, sans-serif;
        }

        &:focus {
          border: none;
          outline: 0;
        }
        &:focus:not(:active) {
          text-shadow: ${theme.boxShadowFocus};
        }

        &:after {
          content: '';
          display: inline-block;
          position: absolute;
          right: -25px;
          top: 50%;
          background: url(${cdown}) no-repeat 50% 50%;
          background-size: 70%;
          width: 20px;
          height: 20px;
          margin-top: -10px;
          transition: ${theme.transitionBase};
          @media (min-width: 960px) {
            right: 0;
          }
        }
        &:hover {
          color: var(--base-color);
          border-bottom: 2px solid var(--base-color);
        }
        &:active {
          outline: 0;
        }
      }
    }

    &__submenu {
      background-color: #fff;
      padding-left: 0 !important;

      a, p {
        line-height: 1;
        white-space: normal;
        padding: 0 10px;
        font-size: ${theme.fontSizeS};
      }

    }
  }

  @media only screen and (max-width: ${theme.breakpoints.tabletLandscape}) {
    width: 100%;
    height: 100%;
    padding-top: 120px;
    position: fixed;
    transform: translateX(0%);
    z-index: 1001;
    top: 0;
    left: 100%;
    right: 0;
    bottom: 0;
    background: white;
    transition: ${theme.transitionBase};
    overflow-y: auto;

    .${theme.classNames.isMenuOpen} & {
      transform: translateX(-100%);
    }

    .header__nav {
      margin: 0 auto;
      width: ${theme.layoutWidth};

      &__menu {
        display: inline-block;
        //flex-direction: column;
        padding-left: 0;
        li {
          margin: 15px 0;
          a, p {
            font-size: 20px;
          }
        }
        &__item {
          // border-top: 1px solid ${theme.colors.light};
          text-align-last: center;
          > button {
            width: 100%;
            text-align: left;

            &[aria-expanded='true'] {
              &:after {
                transform: rotate(180deg);
              }
            }
          }
          > a,
          > button {
            display: block;
            @media (min-width: 0) {
              padding: 0;
            }
            @media (min-width: 960px) {
              padding: 20px 0;
            }
            &:focus {
              border: none;
              color: ${theme.colors.black};
              text-shadow: none;
            }
            &:focus:not(:active) {
              text-shadow: none;
            }
          }

          ul {
            a {
              margin: 5px 0;
            }
            li:last-child {
              a {
                margin-bottom: 20px;
              }
            }
          }
        }
      }
      &__submenu {
        display: block;
        max-height: 0;
        overflow: hidden;
        transition: ${theme.transitionBase};

        &[hidden='false'] {
          max-height: 400px;
        }
      }
    }

    .header__nav__close-btn {
      position: absolute;
      top: 20px;
      right: 20px;
      padding: 0;
      height: 30px;
      width: 30px;
      border: none;
      text-indent: -8000px;
      overflow: hidden;
      background: url(${closeicon}) no-repeat 50% 50%;
      background-size: 90%;
    }
  }

  @media only screen and (min-width: ${theme.breakpoints.tabletLandscape}) {
    display: flex;
    flex: 1;
    align-items: center;
    margin: 0 70px 0 -50px;
    height: ${headerDesktopHeight};

    .navbar-toggle {
      display: none;
    }

    .header__nav {
      flex: 1;
      height: 100%;

      &__menu {
        display: flex;
        justify-content: space-between;
        height: 100%;
        align-items: center;
        padding: 0;
        &__item {
          position: relative;
          min-height: 100%;
          align-items: center;
          > a,
          > button {
            //font-weight: ${theme.fontWeightBold};
            font-size: ${theme.fontSizeL};
            white-space: nowrap;
          }

          button {
            &:after {
              right: -25px;
            }

            &[aria-expanded='true'] {
              &:after {
                transform: rotate(180deg);
              }
            }
          }
        }
      }

      &__submenu {
        display: none;
        position: absolute;
        z-index: 5;
        top: 0;
        width: max-content;
        border-top: none;
        @media (min-width: 0) {
          left: -15px;
          //right: -30px;
        }
        @media (min-width: 1500px) {
          //left: -90%;
          //right: -50px;
        }
        border-top: none;
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
        padding-top: 65px !important;
        padding-right: 15px;
        padding-left: 15px !important;
        box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);

        li {
          a, p {
            font-family: Lato, sans-serif;
            position: relative;
            display: flex;
            justify-content: center;
            @media (min-width: 0) {
              font-size: 16px;
            }
            @media (min-width: 1500px) {
              font-size: 20px;
            }
            //border: 2px solid;
            padding: 9px;
            //border-radius: 25px;
            //border-color: #074788 !important;
            &:hover {
              //color: #0b6dd2;
              //box-shadow: 4px 4px 4px #074788;
              //transition: ease 0.2s;
              color: var(--base-color);
              border-bottom: 2px solid var(--base-color);
            }
          }
          background: #fff;
          text-align: center;
          padding: 5px 0;
          margin: 10px 0;
          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            padding-bottom: 20px;
          }
        }
      }
    }
    .header__nav__close-btn {
      display: none;
    }
  }

  @media only screen and (min-width: 1150px) {
    margin: 0 70px;
  }

  .inline-flex-wrapper {
    li {
      max-width: 300px;
    }
    a {
      outline: 0;
    }
    @media (min-width: 0px) {
      align-self: flex-start;
      padding: 0 10px !important;
      margin-bottom: 0 !important;
      margin-top: 0 !important;
    }
    @media (min-width: 960px) {
      padding: 0 25px !important;
    }
  }
  .labelTitle {
    @media (min-width: 0) {
      font-family: Lato, sans-serif;
      font-size: 18px;
      font-weight: bold;
      color: var(--base-color) !important;
    }
  }
`;

export const Logo = styled.img`
  width: 180px;
  height: auto;
  @media (min-width: 1100px) {
    width: 200px;
  }
  @media (min-width: 1500px) {
    width: 220px;
  }
`;

export const PhoneDiv = styled.div`
  max-width: 150px;
  margin: 0 auto;
  border-radius: 25px;
  color: var(--base-color);
  border: 2px solid;
  padding: 9px;
  a {
    display: inline-block;
    background: white;
    border-radius: 22px;
    color: var(--base-color) !important;
    text-decoration: none;
    text-align: center;
    font-size: 16px !important;
  }

  span {
    display: initial;
  }

  i {
    display: inline;
    padding: 10px 3px;
  }

  &:hover {
    color: var(--base-color);
    box-shadow: 4px 4px 4px var(--base-color);
    transform: scale(1.1);
    transition: ease 0.2s;
  }

  @media (min-width: 960px) {
    position: fixed;
    border-radius: 25px;
    top: 40px;
    right: 20px;
    span {
      display: none;
    }
  }
  @media (min-width: 1100px) {
    right: 40px;
  }
  @media (min-width: 1650px) {
    right: 20px;
    span {
      display: initial;
    }
  }

  //@media (min-width: 1200px) {
  //  span {
  //    display: initial;
  //  }
  //}
  //@media (min-width: 1500px) {
  //  span {
  //    display: none;
  //  }
  //}
  //@media (min-width: 1650px) {
  //  span {
  //    display: initial;
  //  }
  //}
`;

export const QuoteDiv = styled.div`
  max-width: 150px;
  margin: 1rem auto;
  border-radius: 25px;
  background: var(--base-color);
  border: 2px solid var(--base-color);
  padding: 9px;
  a {
    display: inline-block;
    background: var(--base-color);
    border-radius: 22px;
    color: white !important;
    text-decoration: none;
    text-align: center;
    font-size: 16px !important;
  }

  &:hover {
    color: var(--base-color);
    box-shadow: 4px 4px 4px var(--base-color);
    transform: scale(1.1);
    transition: ease 0.2s;
  }

  @media (min-width: 960px) {
    margin: 0 auto;
    position: fixed;
    border-radius: 25px;
    top: 40px;
    right: 80px;
  }
  @media (min-width: 1100px) {
    right: 100px;
  }
  @media (min-width: 1650px) {
    right: 180px;
  }
  @media (min-width: 1800px) {
    right: 200px;
    padding: 0.5rem 1.5rem;
  }
`;
