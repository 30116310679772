import styled from 'styled-components';
import theme from '../../theme';

export const LayoutDiv = styled.div`
  position: relative;
  margin-top: 90px;
  @media (min-width: 960px) {
    margin-top: 110px;
  }
`;

const gridDefaults = {
  breakpoint: theme.breakpoints.tabletLandscape,
  margin: '1%',
  width: 'auto',
};

export const Inner = styled.section.attrs(props => ({
  id: props.id,
}))`
  margin: 0 auto;
  width: ${props => (props.width ? props.width : theme.layoutWidth)};
  max-width: ${props => (props.maxWidth ? props.maxWidth : theme.layoutWidthMax)};
  text-align: left;
  position: relative;
  ${props => props.customCSS && props.customCSS}
`;

export const GridRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  @media only screen and (min-width: ${theme.breakpoints.tabletLandscape}) {
    flex-direction: row;
  }
  ${props => props.customCSS && props.customCSS}
`;

export const GridColumn = styled.div`
    @media only screen and (min-width: ${gridDefaults.breakpoint}) {
        ${props =>
  !props.width &&
  `
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 1;
        `}
        width: ${props => (props.width ? props.width : gridDefaults.width)};
        margin: 0 ${props => (props.margin ? props.margin : gridDefaults.margin)};

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        ${props => props.customCSS && props.customCSS}
    }
`;
