let ROOT_URL = '#';
if (typeof window !== `undefined`) {
  ROOT_URL = window.location.protocol + '//' + window.location.host;
}
export const HeaderData = [
  {
    id: '2995c560d01550925266a1c9afa295d0',
    link: '/',
    image: 'images/Stanford Sonoma Logo.png',
  },
  {
    id: 'b9389cee638bf34d80cbb9d2c7e0a946',
    title: 'Services',
    link: '/services',
    label: [
      {
        id: 'e3ac2591e5922046d30f9a64e9f5aa36',
        title: '',
        subTitles: [
          {
            id: 'b066d7ac-5c66-4888-a0b9-90f2073bfcc8',
            title: 'Custom Fabrication',
            link: '/services/custom-fabrication',
          },
          // {
          //   id: '3cc4f665-1206-473b-8f12-335fdf12062e',
          //   title: 'Restaurant Equipment',
          //   link: 'https://www.onlinerestaurantsupplies.com/',
          //   external: true,
          // },
          {
            id: 'c786b228-f3e7-43a1-b7e4-3b11ddc1f243',
            title: 'Architectural Design Services',
            link: '/services/architectural-design',
          },
        ],
      },
    ],
  },
  {
    id: 'e3ac2591e5922046d30f9a64e9f5aa36',
    title: 'Solutions',
    link: '/solutions',
    label: [
      {
        id: 'e6d892a2-f21f-4d97-a282-b48d4896ab4d',
        title: 'Solutions by Sector',
        link: '/solutions/solutions-by-sector',
        subTitles: [
          {
            id: '0840be1e-9276-4e69-94bb-63f68bcd9619',
            title: 'Education',
            //link: '/solutions/solutions-by-sector/education',
          },
          {
            id: 'ccbf895e-dd00-42fb-a0e3-e5c42c5ebcbc',
            title: 'Foodservice',
            //link: '/solutions/solutions-by-sector/foodservice',
          },
          {
            id: 'cc7c304f-ed2a-4f8e-bdc7-0baee231f142',
            title: 'Government',
            //link: '/solutions/solutions-by-sector/government',
          },
          {
            id: '661dbd81-8e71-494a-b4d9-513a4eac2abc',
            title: 'Healthcare',
            //link: '/solutions/solutions-by-sector/healthcare',
          },
          {
            id: 'cc7c304f-ed2a-4f8e-bdc7-aaaaa231f14256',
            title: 'Hospitality',
            //link: '/solutions/solutions-by-sector/hospitality',
          },
          {
            id: '04108b83-ee9c-4881-8b74-9res123ca56a4e1',
            title: 'Residential',
            //link: '/solutions/solutions-by-sector/residential',
          },
          {
            id: '04108b83-ee9c-4881-8b74-9bc9ca56a4e1',
            title: 'Retail',
            //link: '/solutions/solutions-by-sector/retail',
          },
          {
            id: '04108b83-ee9c-4881-8b74-9bc777sod56a4e1',
            title: 'Work in Progress',
            //link: '/solutions/solutions-by-sector/retail',
          },
        ],
      },
      {
        id: 'd055396c-f24b-4550-9b57-a0a227bc1428',
        title: 'Solutions by Role',
        link: '/solutions/solutions-by-role',
        subTitles: [
          {
            id: '13f553bf-c0d8-4bed-b2b8-8970cdda7118',
            title: 'Chefs',
            //link: '/solutions/solutions-by-role/chefs',
          },
          {
            id: '27da792b-ef40-46f9-85a0-67d5adad7ef0',
            title: 'Dealers',
            //link: '/solutions/solutions-by-role/dealers',
          },
          {
            id: '77911823-4fa2-4906-b769-7ffa48c9bf80',
            title: 'Engineer',
            //link: '/solutions/solutions-by-role/Estimating',
          },
          {
            id: '18372253-84aa-4916-823d-82f774868a93333',
            title: 'Estimating',
            //link: '/solutions/solutions-by-role/facilities',
          },
          {
            id: '18372253-84aa-4916-823d-82f774868a92',
            title: 'Facilities',
            //link: '/solutions/solutions-by-role/facilities',
          },
          {
            id: '17e638e6-97ce-49cc-bf3a-5fdb733aeb9c',
            title: 'Homeowners',
            //link: '/solutions/solutions-by-role/homeowners',
          },
        ],
      },
    ],
  },
  {
    id: '7135c6054aa1f7c2a6e668d44f540072',
    title: 'Resources',
    link: '/resources',
    label: [
      {
        id: '0f83176d-a7c8-4f22-9ab4-2a00c7320419',
        title: '',
        subTitles: [
          {
            id: 'bb2ee982-edc3-4932-b199-315eb6b7c63c',
            title: 'All Resources',
            link: '/resources/all-resources/page-1',
          },
          {
            id: '3d681128-4b49-4f19-9bd3-09d3fe921fb3',
            title: 'Project Gallery',
            link: '/resources/project-gallery',
          },
        ],
      },
    ],
  },
  {
    id: 'a569b2e135f3e21641defddcf1445a02',
    title: 'Our Company',
    link: '/our-company',
    label: [
      {
        id: '96981c0d-d735-4535-9540-00c4fff2ed15',
        title: '',
        subTitles: [
          {
            id: '4b6e0b35ad532d31d25ce0437a2a98b5',
            title: 'About Us',
            link: '/our-company/about',
          },
          {
            id: '6fa3a143fcbf15951becc9fca0a6eccc',
            title: 'Company News',
            link: '/our-company/news/page-1',
          },
          {
            id: '9b1a89e9-597b-4899-bcc8-82c1992e6653',
            title: 'Careers',
            link: '/our-company/careers',
          },
          {
            id: '9b1a89e9-597b-1111-bcc8-81c1992e9653',
            title: 'View Our COVID-19 STATEMENT',
            link: `${ROOT_URL}/documents/Stanford_Covid_WebStatement.pdf`,
            external: true,
          },
          // {
          //   id: '9c25291b7bcd263256a77c6jao123413a6850c',
          //   title: 'Online Restaurant Supplies',
          //   link: 'https://stanfordequipment.com/',
          //   external: true,
          // },
          {
            id: '9c25291b7bcd263256a77c6413a6850c',
            title: 'Contact Us',
            link: '/our-company/contact',
          },
        ],
      },
    ],
  },
];
