import React from 'react';
import '../../node_modules/@fortawesome/fontawesome-free/css/all.css';
import '../../static/styles/style.css';

import { LayoutDiv } from './ui-elements/Grid';

import Header from './header';
import Footer from './footer';

const Layout = (data) => {
  return (
    <>
      <Header />
      <LayoutDiv>{data.children}</LayoutDiv>
      <Footer />
    </>
  );
};

export default Layout;
