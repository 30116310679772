const theme = {
  layoutWidth: '90%',
  layoutWidthMax: '1170px',
  layoutWidthMaxWide: '1520px',

  contentLayout: '770px',

  bodyBackground: '#fff',

  breakpoints: {
    mobile: '400px',
    tabletPortrait: '600px',
    tabletLandscape: '960px',
    tabletLandscapeNum: 960,
  },

  fontFamilyBase: 'proxima-nova, sans-serif',
  fontFamilyHeadings: 'eveleth-clean-regular, sans-serif',
  fontFamilyMono: 'novel-mono-pro, sans-serif',

  fontSizeBody: '18px',

  fontSizeS: '0.89rem',
  fontSizeXS: '0.77rem',
  fontSizeL: '1.1rem',
  fontSizeXL: '1.33rem',

  fontWeightThin: '300',
  fontWeightRegular: '400',
  fontWeightSemibold: '600',
  fontWeightBold: '700',
  fontWeightBlack: '800',

  lineHeightS: '1.33rem',
  lineHeightBase: '1.5rem',
  lineHeightL: '2rem',

  marginBase: '1.5em',
  marginXS: '.5em',
  marginS: '.75em',
  marginM: '1rem',

  spacingS: '8px',
  spacingBase: '16px',
  spacingM: '24px',
  spacingL: '32px',
  spacingXL: '48px',
  spacingXXL: '56px',

  borderRadiusBase: '16px',
  borderRadiusXS: '4px',
  borderRadiusS: '8px',
  borderRadiusL: '24px',
  borderRadiusXL: '32px',
  borderBase: '1px solid #c8c9d9',
  borderLight: '1px solid #e5e5ea',
  boxShadowBase: '0 1px 8px rgba(0,0,0,.1)',
  boxShadowMedium: '0 1px 5px rgba(0,0,0,.4)',
  boxShadowHeavy: '0 0 8px rgba(0,0,0,.75)',
  boxShadowFocus: '0 0 8px rgba(255, 218, 0, 1)',
  transitionBase: 'ease-out .3s',

  buttonHeight: '2.45rem',
  formControlHeight: '2.45rem',

  colors: {
    yellow: '#ffda00',
    red: '#dd140b',
    black: '#010101',
    blue: '#006ADB',
    dark: '#575757',
    darkRed: '#b0150b',
    light: '#D0D0D6',
    lightGrey: '#FBFBFC',
    grey: '#919194',
    medium: '#707070',
    silver: '#c8c9d9',
    green: '#2ec252',
    pageColor: 'var(--base-color)',
  },

  classNames: {
    expanded: 'expanded',
    active: 'active',
    inactive: 'inactive',
    isMenuOpen: 'isMenuOpen',
    hasModal: 'hasModal',
    selected: 'selected',
  },
};

export default theme;
